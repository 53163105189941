<!-- <section class="hero is-fullheight video">
  <div class="hero-video">
    <video [muted]="'muted'" autoplay loop>
      <source src="assets/klipp.webm" type="video/webm; codecs=vp9,vorbis">
    </video>
  </div>
  <div class="hero-body">
    <div class="container">
      <h1 class="title">Hello</h1>
    </div>
  </div>
</section>
 -->

<section class="section">
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-6 has-text-centered">
        <img class="" width="350" height="*" src="assets/logocolored.png">
        <h1 class="title">Gardenova Kft.</h1>
        <p class="subtitle">Mindenünk a gyümölcs</p>
        <video #video (canplay)="video.play()" (loadedmetadata)="video.muted = true" controls>
          <source src="https://gardenova.hu/static/video/campaign_2020_10/klipp.webm"
            type="video/webm; codecs=vp9,vorbis">
          <source src="https://gardenova.hu/static/video/campaign_2020_10/klipp_vp8.webm" type="video/webm">
          <source src="https://gardenova.hu/static/video/campaign_2020_10/klipp.mp4" type="video/mp4">
          <!-- <source src="assets/klipp.mp4" type="video/mp4"> -->
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6 has-text-centered">
        <p class="content is-medium">A Gardenova Kft. a Mezei családi farm tulajdonában álló cég, melynek fő profilja
          alma, meggy és szilva gyümölcsök termesztése. Családi vállalkozásunkat Mezei István növényorvos alapította
          1991-ben, amely a mai napig főtevékenységként gyümölcs termesztéssel foglalkozik.</p>
        <p class="content is-medium">Jelenleg cégünk Nyírtass külterületen 180 ha területen gazdálkodik, melyből
          50 ha gyümölcs, ebből 20 ha meggy, 30 ha alma. Fő gyümölcsünk az alma, amelyből a legmodernebb - szuper
          intenzív - technológiával 15 ha
          áll rendelkezésünkre.</p>
        <p class="content is-medium">Az ültetvényeink olasz oltványokkal (Mazzoni, Fruit Security) kerültek
          beültetésre, olasz és osztrák
          támrendszerrel, automata öntözőrendszerrel és tápoldatozással, jéghálóval és fagyvédelmi gépekkel
          felszereltek.
          Cégünk GLOBALG.A.P tanúsítvánnyal rendelkezik.
        </p>
      </div>
    </div>
  </div>
  <hr class="mt-6 mb-6">
  <div class="container">
    <div class="columns is-centered mb-6">
      <div class="column is-6 has-text-centered">
        <p class="title is-spaced">Almafajtáink</p>
        <p class="subtitle">Cégünk több, mint 10 alma fajtát termel, amelyből 7 kiemelt fajtát tudunk szuper intenzív
          körülmények között, I. osztályú áruként éves szinten 750.000 kg mennyiségben
          a vásárlóink részére biztosítani.
        </p>
      </div>
    </div>
    <div class="columns is-centered pb-6">
      <div class="column is-4 has-text-centered" *ngFor="let item of apples.slice(0,3)">
        <figure class="image container is-128x128">
          <img src="assets/apples/{{item.image_path}}">
        </figure>
        <p class="subtitle is-size-4 pt-3">
          {{item.name}}
        </p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columns is-centered pt-5 pb-6">
      <div class="column is-4 has-text-centered" *ngFor="let item of apples.slice(3,6)">
        <figure class="image container is-128x128">
          <img src="assets/apples/{{item.image_path}}">
        </figure>
        <p class="subtitle is-size-4 pt-3">
          {{item.name}}
        </p>
      </div>
    </div>
    <div class="columns is-centered pt-5">
      <div class="column is-4 has-text-centered" *ngFor="let item of apples.slice(6)">
        <figure class="image container is-128x128">
          <img src="assets/apples/{{item.image_path}}">
        </figure>
        <p class="subtitle is-size-4 pt-3">
          {{item.name}}
        </p>
      </div>
    </div>
  </div>
  <hr class="mt-6 mb-6">
  <div class="container">
    <div class="columns">
      <div class="column has-text-centered">
        <p class="title is-spaced">Elérhetőségeink</p>
        <p class="content">
          Gardenova Kft.
          <br>Székhely: 4522 Nyírtass, Bem út 23/B.
          <br>Cégjegyzékszám: 15-09-077043
          <br>Adószám: 23127182-2-15
          <br>Email: <a href="mailto:hello@gardenova.hu">hello@gardenova.hu</a>
        </p>
        <p class="content">
          <strong>Telefonszám</strong>
          <br>Mezei István <a href="tel:+36303068911">+36303068911</a>
          <br>Mezei Péter <a href="tel:+36304375490">+36304375490</a>
        </p>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-8 has-text-centered">
        <figure class="image">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.9519007336676!2d22.04806931557375!3d48.11979456037858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDjCsDA3JzExLjMiTiAyMsKwMDMnMDAuOSJF!5e1!3m2!1sen!2shu!4v1600605232397!5m2!1sen!2shu"
            width="90%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0"></iframe>
        </figure>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Gardenova Kft.</strong> a Mezei családi farm tulajdona.
        <br>Minden jog fenntartva 2020.
      </p>
    </div>
  </footer>
</section>